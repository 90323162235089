import crudGetters from "@/store/templates/crud/getters";
import { isNil } from "lodash";

export default {
  company: state => state.model,
  id: state => state.model.id,
  name: state => state.model.name,
  vatNumber: state => state.model.vatNumber,
  vatRate: state => state.model.vatRate,
  status: state => state.model.status,

  street: state => state.model.address.street,
  streetNumber: state => state.model.address.streetNumber,
  postalCode: state => state.model.address.postalCode,
  box: state => state.model.address.box,
  cityName: state => state.model.address.cityName,
  countryName: state =>
    state.country[state.model.address.country]
      ? state.country[state.model.address.country].name
      : state.model.address.country,
  country: state => state.model.address.country,
  addressId: state => state.model.address.id,
  paymentTerm: state => state.model.paymentTerm,
  rejectableTerm: state => state.model.rejectableTerm,

  fiscalName: state => state.model.fiscalName,
  fiscalUrl: state => state.model.fiscalUrl,
  fiscalTelephone: state => state.model.fiscalTelephone,
  fiscalEmail: state => state.model.fiscalEmail,
  fiscalStreet: state => state.model.fiscalAddress.street,
  fiscalStreetNumber: state => state.model.fiscalAddress.streetNumber,
  fiscalPostalCode: state => state.model.fiscalAddress.postalCode,
  fiscalBox: state => state.model.fiscalAddress.box,
  fiscalCityName: state => state.model.fiscalAddress.cityName,
  fiscalCountryName: state =>
    state.country[state.model.fiscalAddress.country]
      ? state.country[state.model.fiscalAddress.country].name
      : state.model.fiscalAddress.country,
  fiscalCountry: state => state.model.fiscalAddress.country,
  fiscalAddressId: state => state.model.fiscalAddress.id,

  languageName: state =>
    state.language[state.model.language]
      ? state.language[state.model.language].name
      : state.model.language,
  language: state => state.model.language,

  telephoneNumbers: state => state.model.telephoneNumbers,
  emailAddresses: state => state.model.emailAddresses,

  urls: state => state.model.urls.filter(url => url.url),
  facebookLinks: (state, getters) => {
    return !isNil(getters.urls)
      ? getters.urls.filter(url => url.type === "facebook")
      : null;
  },

  categories: state =>
    !isNil(state.model.categories)
      ? state.model.categories.map(categoryId =>
          state.category ? state.category[categoryId] : categoryId
        )
      : null,
  customers: state =>
    state.model.isCustomer
      ? state.model.customers.map(costumerId =>
          state.customer ? state.customer[costumerId] : costumerId
        )
      : null,
  primaryCustomer: (state, getters) =>
    state.model.isCustomer
      ? getters.customers.filter(function(customer) {
          return customer.isPrimary;
        })[0]
      : null,
  primaryUser: (state, getters) => {
    return state.model.isCustomer
      ? state.user[getters.primaryCustomer.user]
      : null;
  },
  customerCompanies: (state, getters) =>
    !isNil(state.model.customer)
      ? getters.customer.companies.map(companyId => state.company[companyId])
      : null,
  contactPersons: state =>
    !isNil(state.model.contactPersons)
      ? state.model.contactPersons.map(
          contactPersonId => state.contactPerson[contactPersonId]
        )
      : null,
  offers: state =>
    !isNil(state.model.offers)
      ? state.model.offers.map(offerId => state.offer[offerId])
      : null,
  logs: state =>
    (state.model.logs || [])
      .map(logId => state.log[logId])
      .filter(log =>
        ["email", "text", "phone"].includes(log.type.toLowerCase())
      ),
  filterLogs: state => filterId =>
    (state.model.logs || [])
      .map(logId => state.log[logId])
      .filter(
        log =>
          ["filter"].includes(log.type.toLowerCase()) &&
          (!filterId || log.filters.includes(filterId))
      ),
  notes: state => state.model.notes,
  salesDataUpdatedAt: state => state.model.salesDataUpdatedAt,
  isCustomer: state => state.model.isCustomer,
  isReseller: state => state.model.isReseller,
  isFiscalDifferent: state => state.model.isFiscalDifferent,
  isPaused: state => state.settings.isPaused,
  paused: state => state.model.paused,
  pausedFrom: state => state.settings.pausedFrom,
  pausedTill: state => state.settings.pausedTill,
  filterComment: state => state.model.filterComment,

  //billing data
  billingName: state => state.model.billingData.name,
  billingVatNumber: state => state.model.billingData.vatNumber,
  billingUrl: state => state.model.billingData.url,
  billingTelephoneNumber: state => state.model.billingData.telephoneNumber,
  billingEmailAddress: state => state.model.billingData.emailAddress,
  billingStreet: state => state.model.billingData.street,
  billingStreetNumber: state => state.model.billingData.streetNumber,
  billingPostalCode: state => state.model.billingData.postalCode,
  billingBox: state => state.model.billingData.box,
  billingCity: state => state.model.billingData.city,
  billingCountry: state => state.model.billingData.country,
  billingCountryName: state => state.model.billingData.country.name,
  isAutoBilled: state => state.model.isAutoBilled,
  invoiceRemark: state => state.model.billingData.invoiceRemark,
  ...crudGetters
};
